import React, { useCallback } from "react";
import { Col } from "antd";
import { Form, Select } from "formik-antd";
import { useTranslation } from "react-i18next";

function DateVerb({
  setFilterVerb,
}: {
  setFilterVerb: (value: string) => void;
}) {
  const { t } = useTranslation();

  const settingFilterVerb = useCallback(
    (value) => {
      setFilterVerb(value);
    },
    [setFilterVerb],
  );

  return (
    <Form.Item name="verb">
      <Select name="verb" size="large" onChange={settingFilterVerb}>
        <Select.Option value="during">{t("translation:during")}</Select.Option>
        <Select.Option value="before">{t("translation:before")}</Select.Option>
        <Select.Option value="after">{t("translation:after")}</Select.Option>
      </Select>
    </Form.Item>
  );
}

function StringVerb() {
  const { t } = useTranslation();

  return (
    <Form.Item name="verb">
      <Select name="verb" size="large">
        <Select.Option value="equals">{t("translation:is")}</Select.Option>
        <Select.Option value="not">{t("translation:not")}</Select.Option>
        <Select.Option value="sw">{t("translation:starts_with")}</Select.Option>
        <Select.Option value="ew">{t("translation:ends_with")}</Select.Option>
        <Select.Option value="inc">{t("translation:includes")}</Select.Option>
      </Select>
    </Form.Item>
  );
}

function NumberVerb() {
  const { t } = useTranslation();

  return (
    <Form.Item name="verb">
      <Select name="verb" size="large">
        <Select.Option value="equals">{t("translation:is")}</Select.Option>
        <Select.Option value="not">{t("translation:not")}</Select.Option>
        <Select.Option value="gt">
          {t("translation:greater_than")}
        </Select.Option>
        <Select.Option value="lt">{t("translation:less_than")}</Select.Option>
      </Select>
    </Form.Item>
  );
}

function DefaultVerb() {
  const { t } = useTranslation();
  return (
    <Form.Item name="verb">
      <Select name="verb" size="large">
        <Select.Option value="equals">{t("translation:is")}</Select.Option>
        <Select.Option value="not">{t("translation:not")}</Select.Option>
        <Select.Option value="sw">{t("translation:starts_with")}</Select.Option>
        <Select.Option value="ew">{t("translation:ends_with")}</Select.Option>
        <Select.Option value="inc">{t("translation:includes")}</Select.Option>
        <Select.Option value="gt">
          {t("translation:greater_than")}
        </Select.Option>
        <Select.Option value="lt">{t("translation:less_than")}</Select.Option>
      </Select>
    </Form.Item>
  );
}

/**
 * A React component that renders different verb components based on the provided type.
 *
 * @param {Object} props - The properties object.
 * @param {string} props.type - The type of the verb, which determines which component to render.
 * @param {function} props.setFilterVerb - A function to set the filter verb value.
 *
 * @returns {JSX.Element} The appropriate verb component wrapped in a Col component.
 */
export default function Verb({
  type,
  setFilterVerb,
}: {
  type: string;
  setFilterVerb: (value: string) => void;
}) {
  return (
    <Col span={2}>
      {(() => {
        if (type === "DateTime") {
          if (["during", "before", "after"].includes(type)) {
            return <DateVerb setFilterVerb={setFilterVerb} />;
          } else {
            //comparison column on a datetime column
            return <NumberVerb />;
          }
        } else if (type === "Number") {
          return <NumberVerb />;
        } else if (type === "String" || type === "UUID") {
          return <StringVerb />;
        } else {
          return <DefaultVerb />;
        }
      })()}
    </Col>
  );
}
