import { useTranslation } from "react-i18next";
import { iField, Integration } from "/app/src/models";
import { useCallback, useMemo } from "react";
import { Form, SubmitButton } from "formik-antd";
import { Formik, FormikProps } from "formik";
import { Col, Row } from "antd";
import MappingType from "./mappingType";
import DynamicField from "./dynamicFields/dynamicFields";
import { IconBuilder } from "/app/src/components/icons/IconBuilder";
import { allOrderBuilderFields } from "/app/src/constants/orderBuilderFields";

interface FieldFormValues {
  mappingType: string;
  columnId?: number;
  values?: string | (string | number)[];
  required?: boolean;
  viewOnly?: boolean;
}

/**
 * Renders a field component.
 *
 * @param iField - The field object.
 * @param integration - The integration object.
 * @param updateField - A function to update the field.
 * @param createField - A function to create a new field.
 * @param level - The level of the field ("line" or "order").
 */
export default function Field({
  iField,
  integration,
  updateField,
  createField,
  level,
}: {
  iField?: iField;
  integration: Integration;
  updateField: (data: iField) => Promise<void>;
  createField: (data: iField) => Promise<void>;
  level: "line" | "order";
}) {
  const { t } = useTranslation();

  const initialValues = useMemo(() => {
    const initValues: FieldFormValues = {
      mappingType: iField?.type || "notMapped",
      columnId: iField?.columnId,
      values: iField?.values || "",
      required: iField?.required || false,
      viewOnly: iField?.viewOnly || false,
    };
    if (initValues.mappingType === "advanced") {
      initValues.values = iField?.values?.split(";").map((item) => {
        const parsedInt = parseInt(item, 10);
        return isNaN(parsedInt) ? item : parsedInt;
      });
    }
    return initValues;
  }, [iField]);

  const onSubmit = useCallback(
    (values: FieldFormValues) => {
      const data = {
        name: iField?.name,
        type: values.mappingType,
        required: values.required,
        viewOnly: values.viewOnly,
        columnId: values.columnId,
        level,
      };
      if (Array.isArray(values.values)) {
        data["values"] = values.values.join(";");
      } else {
        data["values"] = values.values;
      }
      if (iField?.id) {
        return updateField({ ...data, id: iField.id });
      } else {
        return createField(data);
      }
    },
    [createField, iField.id, iField?.name, level, updateField],
  );

  const fieldForm: (props: FormikProps<FieldFormValues>) => JSX.Element =
    useCallback(
      ({ dirty, isSubmitting, values }) => (
        <Form layout="vertical">
          <Row justify="end" className="flex items-center">
            <Col className="pl-4" span={8}>
              <MappingType />
            </Col>
            <Col className="pl-4" span={12}>
              <DynamicField
                type={values.mappingType}
                integration={integration}
              />
            </Col>
            <Col span={4}>
              <Row justify="end">
                <SubmitButton
                  disabled={!dirty || isSubmitting}
                  loading={isSubmitting}
                >
                  {t("translation:save")}
                </SubmitButton>
              </Row>
            </Col>
          </Row>
        </Form>
      ),
      [integration, t],
    );

  const fieldLabel =
    allOrderBuilderFields.find((field) => field.value === iField?.name)
      ?.label || iField?.name;

  return (
    <Row className="pt-6 items-center">
      <Col span={6}>
        <div className="flex justify-between">
          <span>{fieldLabel}</span>
          <span>
            <IconBuilder icon="ArrowRight" />
          </span>
        </div>
      </Col>
      <Col span={18}>
        <Formik
          component={fieldForm}
          enableReinitialize
          initialValues={initialValues}
          onSubmit={onSubmit}
        />
      </Col>
    </Row>
  );
}
