interface ErrorDetail {
  message: string;
  code: number;
}

interface Errors {
  [key: string]:
    | Array<ErrorDetail>
    | { [key: string]: ErrorDetail }
    | Array<string>;
}
/**
 * Function to digest the errors from the API and set the errors in the Formik form
 * @param errors - errors from the API
 * @param setFieldError - Formik's setFieldError function
 */
export function handleSubmissionErrors(
  errors: Errors,
  setFieldError: (field: string, message: string | undefined) => void,
) {
  for (const [key, value] of Object.entries(errors)) {
    if (Array.isArray(value)) {
      // Handle array of error objects
      value.forEach((error) => {
        if (error.message) {
          setFieldError(key, error.message);
        }
      });
    } else if (typeof value === "object") {
      // Handle nested errors
      for (const [k, v] of Object.entries(value)) {
        if (v?.message) {
          setFieldError(k, v.message);
        } else if (Array.isArray(v)) {
          v.forEach((error) => {
            if (error.message) {
              setFieldError(k, error.message);
            }
          });
        }
      }
    }
  }
}
