import React, { useCallback } from "react";
import { useTranslation } from "react-i18next";
import { Integration } from "/app/src/models";
import { Formik, FormikProps } from "formik";
import { Form, Input, Select, SubmitButton } from "formik-antd";
import { Col, Row } from "antd";
import { simpleSchemaBuilder } from "/app/src/helpers";
import DataHook from "./dataHook";
import NextDivider from "/app/src/components/NextUi/Divider";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { integrationService } from "/app/src/services";
import { handlePromiseError } from "/app/src/helpers/api";

interface IntegrationFormValues {
  name: string | undefined;
}

function formatForm(values: IntegrationFormValues): Integration {
  return {
    name: values.name,
  };
}

interface GeneralSettingsFormValues {
  number?: string | number;
  token?: string;
  reportId?: number;
  creationMode?: string;
}

/**
 *
 * @param param0 integration
 * @returns General settings form
 */
export default function GeneralSettings({
  integration,
}: {
  integration: Integration;
}) {
  const { t } = useTranslation();
  const queryClient = useQueryClient();
  const { handleSubmit, reports, creationMode } = DataHook(integration);

  const { mutateAsync: updateIntegration } = useMutation({
    mutationFn: (updatedIntegration: Integration) => {
      return integrationService
        .updateSingle(updatedIntegration.id, updatedIntegration)
        .then(handlePromiseError);
    },
    onSuccess: (response) => {
      queryClient.setQueryData(["integration", integration.id], response);
    },
  });

  /**
   * Update the integration
   */
  const updateIntegrationHandler = useCallback(
    async (values: IntegrationFormValues) => {
      return await updateIntegration({
        id: integration.id,
        ...formatForm(values),
      });
    },
    [integration.id, updateIntegration],
  );

  const updateIntegrationForm: (
    props: FormikProps<IntegrationFormValues>,
  ) => JSX.Element = useCallback(
    ({ dirty, isSubmitting }) => (
      <Form layout="vertical">
        <Row justify="start" gutter={16}>
          <Col span={8}>
            <Form.Item
              name="name"
              label={t("translation:integration_name")}
              hasFeedback={false}
            >
              <Input
                name="name"
                placeholder={t("translation:enter_name")}
                size="large"
              />
            </Form.Item>
          </Col>
          <Col span={6}>
            <SubmitButton
              type="primary"
              size="large"
              block
              disabled={!dirty || isSubmitting}
              style={{ marginTop: "30px" }}
            >
              {t("translation:update")} {t("translation:integration")}
            </SubmitButton>
          </Col>
        </Row>
      </Form>
    ),
    [t],
  );

  const generalSettingsForm: (
    props: FormikProps<GeneralSettingsFormValues>,
  ) => JSX.Element = useCallback(
    ({ dirty, isSubmitting }) => (
      <Form layout="vertical">
        <Row className="pb-3">
          <Col className="text-lg" span={8}>
            {t("translation:general_settings")}
          </Col>
          <Col span={8} offset={8}>
            <Row justify="end">
              <SubmitButton
                disabled={!dirty || isSubmitting}
                loading={isSubmitting}
                type="primary"
              >
                {t("translation:save")}
              </SubmitButton>
            </Row>
          </Col>
        </Row>
        <Row justify="start" gutter={16}>
          <Col span={6}>
            <Form.Item
              name="number"
              label={t("translation:order_builder_creation_type")}
              hasFeedback={false}
            >
              <Select name="number" size="large">
                <Select.Option value="manual">
                  {t("translation:manual")}
                </Select.Option>
                <Select.Option value="automatic">
                  {t("translation:automatic")}
                </Select.Option>
              </Select>
            </Form.Item>
          </Col>
          <Col span={6}>
            <Form.Item
              name="token"
              label={t("translation:order_creation_type")}
              hasFeedback={false}
            >
              <Select name="token" size="large">
                <Select.Option value="Pick">
                  {t("translation:pick")}
                </Select.Option>
                <Select.Option value="Put">
                  {t("translation:put")}
                </Select.Option>
                <Select.Option value="Count">
                  {t("translation:count")}
                </Select.Option>
              </Select>
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item
              name="reportId"
              label={t("translation:report_build_form")}
              hasFeedback={false}
            >
              <Select
                name="reportId"
                placeholder={t("translation:select_report")}
                size="large"
              >
                {reports?.map((report) => (
                  <Select.Option key={report.id} value={report.id}>
                    {report.name}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col span={4}>
            <Form.Item
              name="creationMode"
              label={t("translation:creation_mode")}
              hasFeedback={false}
            >
              <Select name="creationMode" size="large">
                <Select.Option value="line">
                  {t("translation:line")}
                </Select.Option>
                <Select.Option value="order">
                  {t("translation:order")}
                </Select.Option>
              </Select>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    ),
    [reports, t],
  );

  return (
    <div className="box mt-3">
      <Formik
        component={generalSettingsForm}
        initialValues={{
          number: integration.number,
          token: integration.token,
          reportId: integration.reportId,
          creationMode: creationMode || "order",
        }}
        onSubmit={handleSubmit}
        enableReinitialize
        validationSchema={simpleSchemaBuilder([
          { name: "number", type: "string", required: true },
          { name: "token", type: "string", required: true },
          { name: "reportId", type: "number", required: true },
          { name: "creationMode", type: "string", required: false },
        ])}
      />
      <NextDivider className="mb-3" />
      <Formik
        component={updateIntegrationForm}
        initialValues={{
          name: integration.name,
        }}
        validationSchema={simpleSchemaBuilder([
          { name: "name", type: "string", required: true },
        ])}
        enableReinitialize
        onSubmit={updateIntegrationHandler}
      />
    </div>
  );
}
