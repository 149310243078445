import { Helmet } from "react-helmet";
import { Route, Routes } from "react-router-dom";
import { Row, Col } from "antd";
import { integrationService } from "/app/src/services";
import SideMenuBox from "/app/src/components/generic/components/sideMenuBox";
import Settings from "/app/src/components/apps/orderBuilder/settings";
import OrderBuilder from "./builder";
import { App, Integration as IntegrationType } from "/app/src/models";
import { useNumberId } from "/app/src/hooks/useNumberId";
import { useQuery } from "@tanstack/react-query";
import OrderBuildOrders from "./orders";
import OrderBuildOrder from "./orders/order";

export default function Integration({ app }: { app: App }) {
  const { integrationId } = useNumberId();

  // Get integration
  const { data: integration, isFetching } = useQuery({
    queryKey: ["integration", integrationId],
    queryFn: () => {
      return integrationService.getSingle(integrationId);
    },
    enabled: Boolean(integrationId),
    initialData: { integration: {} },
    select: (data: { integration: IntegrationType }) => {
      return data.integration;
    },
  });

  const manualLinks = [
    {
      path: `/apps/${app.id}/integrations/${integration.id}`,
      label: "settings",
    },
    {
      path: `/apps/${app.id}/integrations/${integration.id}/builder`,
      label: "builder",
    },
    {
      path: `/apps/${app.id}/integrations/${integration.id}/orders`,
      label: "orders",
    },
  ];

  const automaticLinks = [
    {
      path: `/apps/${app.id}/integrations/${integration.id}`,
      label: "settings",
    },
    {
      path: `/apps/${app.id}/integrations/${integration.id}/orders`,
      label: "orders",
    },
  ];

  return (
    <div className="integration">
      <Helmet>
        <title>{integration.name}</title>
      </Helmet>
      {!isFetching && (
        <Row gutter={20}>
          <Col span={6}>
            <SideMenuBox
              links={
                integration.number === "automatic"
                  ? automaticLinks
                  : manualLinks
              }
            />
          </Col>
          <Col span={18}>
            {integration.id && (
              <Routes>
                {integration.number === "manual" && (
                  <Route path="builder" element={<OrderBuilder />} />
                )}
                <Route
                  path="/"
                  element={<Settings integration={integration} />}
                />
                <Route
                  path="orders"
                  element={<OrderBuildOrders integration={integration} />}
                />
                <Route
                  path="orders/:orderId/*"
                  element={<OrderBuildOrder integration={integration} />}
                />
              </Routes>
            )}
          </Col>
        </Row>
      )}
    </div>
  );
}
